import React from 'react';
import NextLink from 'next/link';

type CategorySearchResultsProps = {
  categories: Array<{ name: string; href: string }> | undefined;
};
export const CategorySearchResults: React.FC<CategorySearchResultsProps> = ({ categories }) => {
  if (categories?.length) {
    return (
      <ul className="flex flex-wrap gap-x-0.5 gap-y-1.5">
        {categories.map((category) => (
          <li key={category.href} className="">
            <NextLink
              href={category.href}
              rel="nofollow"
              className="bg-visual text-action-dark mr-0 inline-flex rounded-2xl px-1.5 py-0.5 text-sm no-underline"
            >
              {category.name}
            </NextLink>
          </li>
        ))}
      </ul>
    );
  }

  return null;
};
