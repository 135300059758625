import React, { useDeferredValue, useState } from 'react';
import { useRouter } from 'next/router';
import { InputAdornment, InputBase, Skeleton } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import cn from 'clsx';
import { FormattedMessage, useIntl } from 'react-intl';
import { sendQuickSearchEvent } from '@aph/components/gtm/events/search-gtm';
import { Button } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';
import { Typography } from '@aph/ui/components/typography/typography';
import { capitalizeFirstLetter } from '@aph/utilities/capitalize-first-letter';
import { notEmpty } from '@aph/utilities/not-empty';
import { sortBy } from '@aph/utilities/swedish';
import type {
  IBrandReference,
  ICategoryReference,
  IPageReference,
  IStoreReference,
} from '~/articles/generated/ArticlesClient';
import { SearchSource, SearchType } from '~/articles/generated/ArticlesClient';
import { SearchApiClient } from '~/search/api/SearchApiClient';
import { CategorySearchResults } from './category-search-results';
import { GenericSearchResults } from './generic-search-results';
import { ProducSearchResults } from './product-search-results';

function isStoreReference(value: unknown): value is IStoreReference {
  return (value as IStoreReference).storeName !== undefined;
}

// this is basically a way to trick TS into thinking that we have checked that we have both a name and a href
// and to infer that type
const isSearchResult = (
  value: ReturnType<typeof mapToSearchResult>,
): value is { name: string; href: string } => {
  return notEmpty(value.name) && notEmpty(value.href);
};

const mapToSearchResult = (
  value: IBrandReference | IPageReference | IStoreReference | ICategoryReference,
) => {
  if (isStoreReference(value)) {
    return {
      name: value.storeName,
      href: value.slug,
    };
  }

  return {
    name: value.name,
    href: value.slug,
  };
};

function useQuickSearch(searchPhrase: string) {
  return useQuery({
    queryKey: ['quick-search', { searchPhrase }],
    queryFn: async () => {
      sendQuickSearchEvent(searchPhrase);
      const searchApiClient = new SearchApiClient();
      return searchApiClient.getSearchResult({
        searchPhrase,
        take: 4,
        source: SearchSource.All,
        type: SearchType.QuickSearch,
      });
    },
    select(response) {
      return {
        articles: response?.articles?.results?.slice(0, 4) ?? [],
        categories:
          response.categories?.map(mapToSearchResult).filter(isSearchResult).slice(0, 4) ?? [],
        brands:
          response.brands?.results
            ?.map(mapToSearchResult)
            .filter(isSearchResult)
            .slice(0, 4)
            .map((brand) => ({ ...brand, name: capitalizeFirstLetter(brand.name) }))
            .sort(sortBy('name')) ?? [],
        information:
          response.pages?.results?.map(mapToSearchResult).filter(isSearchResult).slice(0, 4) ?? [],
        pharmacies:
          response.stores?.results
            ?.map(mapToSearchResult)
            .filter(isSearchResult)
            .slice(0, 4)
            .map((pharmacy) => ({
              ...pharmacy,
              name: `Apotek Hjärtat ${pharmacy.name}`,
            })) ?? [],
      };
    },

    enabled: searchPhrase.length > 2,
    staleTime: Infinity,
    gcTime: 2 * 60 * 1000, // 2 minutes
  });
}

interface SearchResultCardProps {
  heading: string;
  children?: React.ReactNode;
}

const SearchResultCard: React.FC<SearchResultCardProps> = ({ heading, children }) => {
  return (
    <div
      className="bg-elevated flex-1 rounded-md py-2"
      role="group"
      aria-roledescription="Sökresultat"
    >
      <Typography
        typography="headingMedium"
        color="text-default"
        className="border-subtle border-b bg-inherit px-2 pb-1"
      >
        {heading}
      </Typography>

      <div className="px-2 pt-2">{children}</div>
    </div>
  );
};

interface SearchResultProps {
  searchPhrase: string;
}

const SearchResult: React.FC<SearchResultProps> = ({ searchPhrase }) => {
  const { data, ...getSearchResultQuery } = useQuickSearch(searchPhrase);
  const router = useRouter();
  const intl = useIntl();

  if (data) {
    const articles =
      data.articles.length > 0 ? (
        <SearchResultCard heading={intl.formatMessage({ id: 'COMMON.HEADER.SEARCH.PRODUCTS' })}>
          <div className="-m-2">
            <ProducSearchResults articles={data.articles} />,
          </div>
        </SearchResultCard>
      ) : null;

    const categories =
      data.categories.length > 0 ? (
        <SearchResultCard heading={intl.formatMessage({ id: 'COMMON.HEADER.SEARCH.CATEGORIES' })}>
          <CategorySearchResults categories={data.categories} />
        </SearchResultCard>
      ) : null;

    const brands =
      data.brands.length > 0 ? (
        <SearchResultCard
          heading={intl.formatMessage({
            id: 'COMMON.HEADER.SEARCH.BRANDS',
            defaultMessage: 'Varumärken',
          })}
        >
          <GenericSearchResults results={data.brands} />
        </SearchResultCard>
      ) : null;

    const information =
      data.information.length > 0 ? (
        <SearchResultCard
          heading={intl.formatMessage({
            id: 'COMMON.HEADER.SEARCH.INFORMATION',
            defaultMessage: 'Information',
          })}
        >
          <GenericSearchResults results={data.information} />
        </SearchResultCard>
      ) : null;

    const pharmacies =
      data.pharmacies.length > 0 ? (
        <SearchResultCard
          heading={intl.formatMessage({
            id: 'COMMON.HEADER.SEARCH.APOTEK',
            defaultMessage: 'Apotek',
          })}
        >
          <GenericSearchResults results={data.pharmacies} />
        </SearchResultCard>
      ) : null;

    const isNoResultsState = !articles && !categories && !brands && !information && !pharmacies;

    return (
      <>
        <div className={cn('bg-default overflow-auto p-1.5', { hidden: isNoResultsState })}>
          <div className="flex flex-col gap-y-1.5 md:hidden">
            {articles}
            {categories}
            {brands}
            {information}
            {pharmacies}
          </div>
          <div className="hidden grid-cols-3 items-start gap-x-1.5 md:grid">
            {articles}
            <div className="flex flex-col gap-y-1.5">
              {categories}
              {brands}
            </div>
            <div className="flex flex-col gap-y-1.5">
              {information}
              {pharmacies}
            </div>
          </div>
        </div>

        <div
          className={cn(
            'md:bg-default sticky bottom-0 grid place-items-center px-2 py-1.5 md:place-items-end md:pt-0',
            { hidden: isNoResultsState },
          )}
        >
          <Button
            size="medium"
            variant="primary"
            className="max-md:w-full"
            onClick={() => router.push({ pathname: '/soksida', query: { query: searchPhrase } })}
          >
            Visa alla träffar
            <Icon name="ArrowRight" size="medium" />
          </Button>
        </div>
      </>
    );
  }

  if (getSearchResultQuery.error || getSearchResultQuery.fetchStatus !== 'fetching') {
    return null;
  }

  // simple loading state
  return (
    <div className="bg-default flex flex-col gap-y-1.5 p-1.5 md:grid md:grid-cols-3">
      <div className="bg-elevated rounded-md p-2">
        <Skeleton variant="rounded" height={80} />
      </div>
      <div className="bg-elevated rounded-md p-2">
        <Skeleton variant="rounded" height={80} />
      </div>
      <div className="bg-elevated rounded-md p-2">
        <Skeleton variant="rounded" height={80} />
      </div>
    </div>
  );
};

interface QuickSearchProps {
  placeholder: string;
  onClose: () => void;
}

export const QuickSearch: React.FC<QuickSearchProps> = ({ placeholder, onClose }) => {
  const [value, setValue] = useState('');
  const searchPhrase = useDeferredValue(value);
  const router = useRouter();

  return (
    <div className="bg-elevated flex flex-col overflow-hidden">
      <div className="bg-elevated sticky top-0 z-[1] flex flex-row gap-4 px-2 py-1.5">
        <InputBase
          value={value}
          onKeyDown={(event) => {
            if (event.key === 'Enter') {
              router.push({ pathname: '/soksida', query: { query: value } });
            }
          }}
          autoFocus
          placeholder={placeholder}
          fullWidth
          sx={{
            display: 'flex',
            borderRadius: '100px',
            backgroundColor: (theme) => theme.palette['color/background/default'],
            paddingX: 2.5,
            paddingY: 1.5,

            // The theme has other props on InputBase that we need to override here
            '& .MuiInputBase-input': {
              typography: (theme) => theme.typography.body1,
              color: (theme) => theme.palette['color/text/subtle'],
            },
          }}
          onChange={(event) => {
            setValue(event.target.value);
          }}
          startAdornment={
            <InputAdornment position="start">
              <Icon name="MagnifyingGlass" fill="fill-action" size="large" />
            </InputAdornment>
          }
          endAdornment={
            value.length > 0 ? (
              <InputAdornment position="end">
                <button
                  type="button"
                  aria-label="Rensa"
                  className="focus-within:outline"
                  onKeyDown={(event) => event.stopPropagation()}
                  onClick={() => {
                    setValue('');
                  }}
                >
                  <FormattedMessage id="COMMON.HEADER.SEARCH.CLEAR" />
                </button>
              </InputAdornment>
            ) : null
          }
        />
        <button aria-label="Stäng" type="button" onClick={onClose}>
          <div className="sr-only">
            <FormattedMessage id="COMMON.HEADER.SEARCH.CLOSE" />
          </div>
          <Icon name="Close" size="large" />
        </button>
      </div>
      <SearchResult searchPhrase={searchPhrase} />
    </div>
  );
};
