export const PROMOTED_LINKS = [
  { title: 'Nyheter', href: '/nyheter' },
  { title: 'Kampanjer', href: '/kampanjer' },
  { title: 'Outlet', href: '/kampanjer/outlet' },
  { title: 'Varumärken', href: '/varumarken' },
  {
    title: 'Förebyggande hälsa',
    href: '/tips-och-rad/halsa/forebyggande-halsa',
    feature: 'aph_features_tips_advice_page',
  },
  {
    title: 'Kvinnohälsa',
    href: '/tips-och-rad/halsa/kvinnohalsa---ma-bra-genom-livets-alla-faser',
    feature: 'aph_features_tips_advice_page',
  },
];
