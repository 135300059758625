import React, { forwardRef, useMemo, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { FeatureToggledNextLink } from '@aph/components/common/feature-toggled-next-link';
import { Button, type ButtonProps } from '@aph/ui/components/button/button';
import { formatProductUrl } from '@aph/utilities/format-product-url';
import type { Article } from '~/articles/generated/ArticlesClient';
import { DiklofenalDialog } from './diklofenak-dialog.component';

export type AddArticleToCartButtonProps = {
  onClick: () => void;
} & Pick<ButtonProps, 'isLoading' | 'size' | 'disabled'> &
  Pick<
    Article,
    | 'hasVariants'
    | 'activeSubstances'
    | 'isAvailableForPurchase'
    | 'requiresPrescriptionForPurchase'
    | 'articleUrlSegment'
    | 'name'
  >;

export const AddArticleToCartButton = forwardRef<HTMLButtonElement, AddArticleToCartButtonProps>(
  (
    {
      activeSubstances,
      isAvailableForPurchase,
      requiresPrescriptionForPurchase,
      onClick,
      disabled,
      hasVariants,
      articleUrlSegment,
      isLoading,
      size,
      name,
    }: AddArticleToCartButtonProps,
    forwardedRef,
  ) => {
    const [showDiklofenakDialog, setShowDiklofenakDialog] = useState(false);

    const textId = useMemo(() => {
      if (hasVariants) {
        if (!isAvailableForPurchase) {
          return 'ADD_ARTICLE_TO_CART.BUTTON.NOT.BUYABLE';
        }
        return 'ADD_ARTICLE_TO_CART.BUTTON.HAS_VARIANTS';
      }
      if (requiresPrescriptionForPurchase) {
        return 'ADD_ARTICLE_TO_CART.BUTTON.BUY_VIA_PRESCRIPTION';
      }
      if (isAvailableForPurchase) {
        return 'ADD_ARTICLE_TO_CART.BUTTON.BUYABLE';
      }
      return 'ADD_ARTICLE_TO_CART.BUTTON.NOT.BUYABLE';
    }, [hasVariants, requiresPrescriptionForPurchase, isAvailableForPurchase]);

    const href = useMemo(() => {
      if (hasVariants) {
        return formatProductUrl(articleUrlSegment);
      }
      if (requiresPrescriptionForPurchase) {
        return '/mina-recept/receptlistan/';
      }
      return undefined;
    }, [hasVariants, requiresPrescriptionForPurchase, articleUrlSegment]);

    const handleDisabled = (e: React.MouseEvent<HTMLButtonElement>) => {
      e.preventDefault();
    };

    const onClickProxy = () => {
      const containsDiklofenak = (activeSubstances || [])
        .map((item) => item.toLocaleLowerCase())
        .includes('diklofenak');

      if (containsDiklofenak) {
        setShowDiklofenakDialog(true);
      } else if (!href) {
        onClick();
      }
    };

    const onDialogClose = (confirmed: boolean) => {
      if (confirmed) {
        onClick();
      }
      setShowDiklofenakDialog(false);
    };

    const ariaLabelBuy =
      textId === 'ADD_ARTICLE_TO_CART.BUTTON.BUYABLE' ? `Köp ${name}` : undefined;
    const buttonDisabled =
      (!isAvailableForPurchase && !requiresPrescriptionForPurchase) || disabled;
    const buttonDisabledClasses =
      'bg-action-subtle text-action-subtle hover:bg-action-subtle cursor-auto active:bg-action-subtle';

    return (
      <>
        <Button
          data-pw="add-article-to-cart-button"
          data-testid="AddArticleToCartButton"
          ref={forwardedRef}
          onClick={buttonDisabled ? handleDisabled : onClickProxy}
          width="full"
          asChild={Boolean(href)}
          isLoading={isLoading}
          size={size}
          aria-label={ariaLabelBuy}
          aria-disabled={buttonDisabled}
          className={buttonDisabled ? buttonDisabledClasses : ''}
        >
          {href ? (
            <FeatureToggledNextLink href={href}>
              <FormattedMessage id={textId} />
            </FeatureToggledNextLink>
          ) : (
            <FormattedMessage id={textId} />
          )}
        </Button>
        <DiklofenalDialog showDialog={showDiklofenakDialog} onClose={onDialogClose} />
      </>
    );
  },
);
