import React from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { parseEventTree, sendMainNavigationEvent } from '@aph/components/gtm/events/navigation-gtm';
import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';
import { Button } from '@aph/ui/components/button/button';
import { Icon } from '@aph/ui/components/icon/icon';
import { FeatureToggledNextLink } from '../feature-toggled-next-link';
import { usePrescriptionProfile } from '../header/header-prescription-profile/header-prescription-profile.container';
import { ProfileMenu } from '../header/profile-menu/profile-menu.container';
import type { FeatureToggleableLink } from '../link-list/link-list.component';
import { LogoLink } from './logo-link.component';

export const TOP_NAVIGATION_LINKS: Array<FeatureToggleableLink> = [
  { title: 'Hem', href: '/' },
  { title: 'Rådgivning', href: '/tips-och-rad', feature: 'aph_features_tips_advice_page' },
  { title: 'Klubb Hjärtat', href: '/klubb-hjartat/mina-sidor' },
  {
    title: 'Hitta apotek',
    href: '/hitta-apotek-hjartat',
    isEpiLink: true,
  },
  {
    title: 'Kontakta oss',
    href: '/kundservice/kontakta-oss',
  },
  {
    title: 'Produkter',
    href: '/produkter',
  },
];

export const TopNavigation = () => {
  const intl = useIntl();
  const prescriptionProfile = usePrescriptionProfile();

  const handleLinkClick = (target: FeatureToggleableLink) =>
    sendMainNavigationEvent(parseEventTree(target.href));

  return (
    <nav className="py-1.5 md:py-1">
      <div className="flex w-full items-center justify-between">
        <div className="md:hidden">
          <LogoLink />
        </div>
        <ul className="hidden list-none gap-4 md:flex">
          {TOP_NAVIGATION_LINKS.map((link) => (
            <li key={link.href}>
              <FeatureToggledNextLink
                underline="none"
                sx={{
                  typography: (theme) => theme.typography.body1,
                  color: (theme) => theme.palette['color/text/default'],
                  '&.Mui-focusVisible': {
                    outline: (theme) => `1px solid ${theme.palette['color/border/action']}}`,
                    outlineOffset: '2px',
                  },
                }}
                href={link.href}
                forceAnchorLink={link.isEpiLink}
                featureName={link.feature}
                onClick={() => handleLinkClick(link)}
              >
                {link.title}
              </FeatureToggledNextLink>
            </li>
          ))}
        </ul>
        <div className="flex gap-2">
          <div className="md:hidden">
            <ButtonCircle
              icon="Pill"
              size="medium"
              text={intl.formatMessage({ id: 'COMMON.HEADER.RECIPES' })}
              onClick={prescriptionProfile.onClickButton}
            />
          </div>
          <div className="hidden md:block">
            <Button
              data-pw="top-navigation-button"
              size="small"
              onClick={prescriptionProfile.onClickButton}
            >
              <Icon size="small" name="Pill" />
              <FormattedMessage id="COMMON.HEADER.RECIPES" />
            </Button>
          </div>
          <ProfileMenu />
        </div>
      </div>
    </nav>
  );
};
