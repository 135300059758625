import React, { useState } from 'react';
import { IconButton, SvgIcon } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { useIntl } from 'react-intl';
import Trashcan from '@aph/icons/trashcan.svg';
import theme from '~/styles/theme';

type RemoveItemType = {
  meta: {
    component: {
      identifier: string;
      resolve: () => void;
      reject: () => void;
    };
  };
};

export type RemoveItemProps = {
  loading?: boolean;
  removeItem: (params: RemoveItemType) => void;
  itemTitle?: string;
};

export const RemoveItem: React.FC<RemoveItemProps> = React.memo(
  ({ loading, removeItem, itemTitle }) => {
    const intl = useIntl();
    const [isLoading, setIsLoading] = useState(loading || false);

    const onButtonClick = () => {
      if (isLoading === true) {
        return;
      }
      setIsLoading(true);
      new Promise<void>((resolve, reject) => {
        removeItem({
          meta: {
            component: {
              identifier: 'Checkout.Cart.RemoveItem',
              resolve,
              reject,
            },
          },
        });
      })
        .then(() => {
          setIsLoading(false);
        })
        .catch(() => {
          setIsLoading(false);
        });
    };

    return (
      <IconButton
        data-pw="remove-item-button"
        onClick={onButtonClick}
        disabled={isLoading}
        disableRipple
        aria-label={intl.formatMessage(
          { id: 'CHECKOUT.CART.REMOVE_ITEM.TITLE' },
          { title: itemTitle },
        )}
        data-testid="CART.REMOVE_ITEM.BUTTON"
        sx={{
          alignSelf: 'flex-start',
          '&:focus > svg': {
            color: theme.palette['color/icon/action-dark'],
          },
        }}
      >
        {isLoading === false && (
          <SvgIcon
            fontSize="small"
            htmlColor={theme.palette['color/icon/action']}
            sx={{
              '&:hover': {
                color: theme.palette['color/icon/action-dark'],
              },
            }}
          >
            <Trashcan />
          </SvgIcon>
        )}
        {isLoading === true && <CircularProgress size="14px" color="primary" />}
      </IconButton>
    );
  },
);
