/* eslint-disable prefer-arrow-callback */
import { forwardRef } from 'react';
import type { ImageProps } from '@unpic/react';
import { Image, Source } from '@unpic/react';
import { DevelopmentOnly } from '@aph/components/common/development-only/development-only.component';
import type { Omit } from '@aph/utilities/ts-omit';
import { getPropsForLayout } from '@aph/utilities/unpic-image';

type ContentfulImageOptionsFit = 'pad' | 'fill' | 'scale' | 'crop' | 'thumb';
type ContentfulImageOptionsQuality = number;
type ContentfulCdnParams = {
  fit?: ContentfulImageOptionsFit;
  quality?: ContentfulImageOptionsQuality;
};

type ContentfulImageProps = Omit<ImageProps, 'cdn' | 'background'> & ContentfulCdnParams;

export const ContentfulImage = forwardRef<HTMLImageElement, ContentfulImageProps>(
  function ContentfulImage({ fit, quality, className, alt, priority, unstyled, ...props }, ref) {
    const url = getContentfulImageSrcUrl(props.src, { fit, quality });

    if (url.host !== 'images.ctfassets.net') {
      return (
        <DevelopmentOnly>
          <div className="bg-notice-dark text-inverse rounded-md p-2">
            <b>{url.host}</b> is not a valid CDN url for Contentful images (maybe the asset too
            large, 20MB+)
          </div>
        </DevelopmentOnly>
      );
    }

    const universalProps = getPropsForLayout({
      ...props,
      src: url.toString(),
      cdn: 'contentful',
    });

    // we're using a picture element to support AVIF and WebP formats since Contentfuls image CDN doesn't have content negotiation
    // but it does support AVIF and WebP
    // TEMP: AVIF is disabled until we have a better way to handle preloading etc (smaller size but takes longer to load)
    return (
      <picture className="contents">
        {/*   <Source {...universalProps}  type="image/avif" /> */}
        <Source {...universalProps} type="image/webp" />
        <Image
          {...universalProps}
          ref={ref}
          alt={alt}
          priority={priority}
          unstyled={unstyled}
          className={className}
        />
      </picture>
    );
  },
);

function getContentfulImageSrcUrl(src: string, options: ContentfulCdnParams) {
  const url = src.startsWith('//') ? new URL(`https:${src}`) : new URL(src);

  if (options.fit) {
    url.searchParams.set('fit', options.fit);
  }
  if (options.quality) {
    url.searchParams.set('q', options.quality.toString());
  }

  return url;
}
