import { sendEcommerce, sendEvent } from '@aph/components/gtm/useGtm';
import type { IArticle } from './generated/ArticlesClient';

export type ArticleData = Partial<{
  articleCode: ArticleTrackingData['item_id'];
  name: ArticleTrackingData['item_name'];
  brand: ArticleTrackingData['item_brand'][];
  category: ArticleTrackingData['item_category'][];
  originalPrice: ArticleTrackingData['price'];
  discountedPrice: ArticleTrackingData['discount'];
  isSensitive: ArticleTrackingData['is_sensitive'];
}>;

export interface ArticleTrackingData {
  item_id: string | undefined;
  item_name: string | undefined;
  item_brand: string | undefined;
  item_list_name: string | undefined;
  item_category: string | undefined;
  price: number | undefined;
  discount: number | undefined;
  is_sensitive: boolean | undefined;
}

const convertToTwoDecimals = (value: number | undefined) =>
  value ? Math.round(value * 100) / 100 : undefined;

export const mapArticleToTrackingData = (
  data: Omit<ArticleData, 'listName'>,
  listName: ArticleTrackingData['item_list_name'],
): ArticleTrackingData => {
  const { originalPrice, discountedPrice } = data;
  const discount = originalPrice && discountedPrice ? originalPrice - discountedPrice : undefined;
  const paidPrice = originalPrice && discount ? originalPrice - discount : originalPrice;

  return {
    item_id: data.articleCode,
    item_name: data.name,
    item_brand: data.brand?.at(0),
    item_category: data?.category?.at(0),
    price: convertToTwoDecimals(paidPrice),
    discount: convertToTwoDecimals(discount),
    is_sensitive: data.isSensitive,
    item_list_name: listName,
  };
};

export const extractArticleData = (article: IArticle): ArticleData => ({
  ...article,
  brand: article.brandReferences?.map(({ name }) => name) || [],
  category: article.productCategoryDetails?.map(({ title }) => title) || [],
  originalPrice: article.price?.webPrice,
  discountedPrice: article.price?.salesPrice,
});

export const sendViewItemEvent = (article: IArticle) => {
  const articleTrackingData = mapArticleToTrackingData(extractArticleData(article), '');
  delete articleTrackingData.item_list_name;
  sendEcommerce({
    event: 'view_item',
    ecommerce: {
      items: [
        {
          ...articleTrackingData,
          stock_status: article.stockItem?.stockStatus,
          index: 0,
        },
      ],
    },
  });
};

export const sendSelectItemEvent = (article: IArticle, listName: string, index: number) =>
  sendEcommerce({
    event: 'select_item',
    ecommerce: {
      items: [
        {
          ...mapArticleToTrackingData(extractArticleData(article), listName),
          stock_status: article.stockItem?.stockStatus,
          index: index + 1,
        },
      ],
    },
  });

export const sendViewItemListEvent = (article: IArticle, listName: string, index: number) =>
  sendEcommerce({
    event: 'view_item_list',
    ecommerce: {
      items: [
        {
          ...mapArticleToTrackingData(extractArticleData(article), listName),
          index,
        },
      ],
    },
  });

export const sendMoreCampaignsEvent = (text: string) =>
  sendEvent({
    event: 'more_campaigns',
    click_text: text,
  });

export const sendAccordionCloseEvent = (title: string) =>
  sendEvent({
    event: 'close_product_info',
    info_section: title,
  });

export const sendAccordionOpenEvent = (title: string) =>
  sendEvent({
    event: 'open_product_info',
    info_section: title,
  });
