/* eslint-disable prefer-arrow-callback */
import { forwardRef } from 'react';
import { Cloudinary } from '@cloudinary/url-gen';
import { dpr, format, quality } from '@cloudinary/url-gen/actions/delivery';
import { limitFit } from '@cloudinary/url-gen/actions/resize';
import { auto } from '@cloudinary/url-gen/qualifiers/dpr';
import type { ImageProps } from '@unpic/react';
import { Image } from '@unpic/react';
import type { Omit } from '@aph/utilities/ts-omit';
import { getPropsForLayout } from '@aph/utilities/unpic-image';

const cld = new Cloudinary({
  cloud: { cloudName: 'icase-eu' },
  url: { secureDistribution: 'assets.icanet.se', privateCdn: true, analytics: false },
});

type CloudinaryImageProps = Omit<ImageProps, 'src' | 'cdn'> & {
  publicId: string;
};

export const CloudinaryImage = forwardRef<HTMLImageElement, CloudinaryImageProps>(
  function CloudinaryImage({ publicId, ...props }, ref) {
    const imageProps = getPropsForLayout({ ...props, src: publicId, cdn: 'cloudinary' });

    return (
      <Image
        ref={ref}
        {...imageProps}
        transformer={(options) => {
          // NOTE: we use a transformer here since we need more control and the unpic library doesn't support custom CNAMEs with cloudinary like assets.icanet.se
          return cld
            .image(options.url.toString())
            .resize(limitFit(options.width, options.height))
            .delivery(dpr(auto()))
            .delivery(format('auto'))
            .delivery(quality('auto'))
            .toURL();
        }}
      />
    );
  },
);
