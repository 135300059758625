/* eslint-disable arrow-body-style */
import React, { useEffect } from 'react';
import { useRouter } from 'next/router';
import { useIntl } from 'react-intl';
import { parseEventTree, sendMainNavigationEvent } from '@aph/components/gtm/events/navigation-gtm';
import { useToggle } from '@aph/hooks/use-toggle';
import { ButtonCircle } from '@aph/ui/components/button-circle/button-circle';
import { SideDrawer } from '~/articles/components/side-drawer/side-drawer';
import type { FeatureToggleableLink } from '../link-list/link-list.component';
import { LinkList } from '../link-list/link-list.component';
import { PROMOTED_LINKS } from '../link-list/promoted-links';
import { ProductCategoryNavigation } from './product-category-navigation/product-category-navigation.component';
import { TOP_NAVIGATION_LINKS } from './top-navigation.component';

export const HamburgerMenu = () => {
  const [isMenuExpanded, toggle, setIsMenuExpanded] = useToggle();
  const router = useRouter();
  const { formatMessage } = useIntl();

  useEffect(() => {
    const handleRouteChange = () => {
      setIsMenuExpanded(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, setIsMenuExpanded, toggle]);

  const handleLinkClick = (target: FeatureToggleableLink) =>
    sendMainNavigationEvent(parseEventTree(target.href));

  return (
    <>
      <ButtonCircle size="extraLarge" icon="Menu" onClick={toggle} variant="secondary" />
      <SideDrawer
        title={formatMessage({ id: 'COMMON.HEADER.MENU' })}
        isOpen={isMenuExpanded}
        onClose={toggle}
        anchor="left"
      >
        <div className="flex flex-col gap-1">
          <div className="p-1.25">
            <LinkList
              links={TOP_NAVIGATION_LINKS}
              variant="secondary"
              onLinkClick={handleLinkClick}
              typography="bodyBig"
            />
          </div>
          <div className="p-1.25">
            <LinkList links={PROMOTED_LINKS} variant="primary" onLinkClick={handleLinkClick} />
          </div>
          <div className="p-1.25">
            <ProductCategoryNavigation />
          </div>
        </div>
      </SideDrawer>
    </>
  );
};
